import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { thumb7, hackathonIcon } from "../assets/images";

const CulturePage = () => {
  const navigate = useNavigate();

  return (
    <div className="card-container-homepage">
      <nav className="breadcrumb" style={{ marginBottom: "10px", marginTop: "82px" }}>
        <span><Link to="/more">More</Link> / <span>Culture</span></span>
      </nav>
      <div
        className="card-nodepage"
        style={{ marginLeft: "400px", marginTop: "40px" }}
      >
        <Link to="/hackathon" className="card-link">
          <img
            src={hackathonIcon}
            alt="Hackathon"
            width="30px"
            height="30px"
            style={{ height: "100px", width: "100px" }}
          />
          <div className="card-title-nodepage">Hackathon</div>
          <p>Click to Participate in Hackathon</p>
        </Link>
      </div>
    </div>
  );
};

export default CulturePage;