import React, { useEffect, useState } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import groupimg from "../assets/images/Team.jpeg";
import JD from "../assets/images/Jd.png";
import rlus from "../assets/images/rlus.png";
import delaval from "../assets/images/delaval.png";
import ServiceCard from "./ServiceCard";
import ClientCard from "./ClientCard";
import servicesData from "../assets/data/services.json";
import clientsData from "../assets/data/clients.json";

const HomePage = ({ data }) => {
  const [services, setServices] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    console.log("Props data:", data);
    setServices(servicesData);
    setClients(clientsData);
  }, [data]);

  const clientImages = {
    rlus,
    JD,
    delaval,
  };

  const filteredDocuments = Array.isArray(data.data)
    ? data.data.filter((doc) => doc.path.startsWith("awsappdev/Team Charter/"))
    : [];

  return (
    <div>
      {/* Image Section */}
      <div className="image-section">
        <img src={groupimg} alt="AWS APPDEV" className="img-fluid" />
        <header className="hero-section">
          <div className="hero-overlay">
            <h1>Welcome to AWS APPDEV</h1>
            <p>Empowering businesses with cutting-edge solutions</p>
            <a href="#services" className="btn btn-primary btn-lg">
              Explore Our Teams
            </a>
          </div>
        </header>
      </div>

      
      {/* Services Section */}
      <section id="services" className="container my-5 text-center">
        <h2 className="section-title mb-4">Our Teams</h2>
        <center><div className="row">
          {filteredDocuments.map((doc, index) => (
            <ServiceCard
              key={index}
              iconClass="fas fa-file-alt"
              title={doc.name_of_document}
              description={doc.summary}
            />
          ))}
        </div></center>
      </section>

      {/* Clients Section */}
      <section id="clients" className="container my-5 text-center">
        <h2 className="section-title mb-4">Our Clients</h2>
        <center><div className="row">
          {clients.map((client, index) => (
            <ClientCard
              key={index}
              imgSrc={clientImages[client.imgKey]}
              altText={client.altText}
              clientName={client.clientName}
            />
          ))}
        </div></center>
      </section>
      {/* Mission and Vision Section */}
      <section className="mission-vision-section">
        <center><h2>Our Mission and Vision</h2>
        <p style={{marginLeft:'120px', marginRight:"120px"}}>
          Build best practices & capability using AWS Services & open Source to
          create reusable code base. Upon maturity, acts as a Shared Service &
          Continuous innovation center (Aligned with Industry).
        </p></center>
        <center><h2>Charter:</h2></center>
        <div style={{marginLeft:"250px"}}>
        <ul>
          <li>
            <strong>Develop & Maintain Capability on AWS Services (Own)</strong>
            <ul>
              <li>Scoping limited to App Dev & Containerization</li>
            </ul>
          </li>
          <li>
            <strong>
              Develop & Maintain Capability on AWS Well Architecture Program &
              participate in review / design process (Own)
            </strong>
            <ul>
              <li>Scoping limited to Serverless Application lens</li>
              <li>Container build lens</li>
              <ul>
                <li>Joint responsibility with DevOPS Team</li>
              </ul>
              <li>SaaS lens</li>
            </ul>
          </li>
          <li>
            <strong>Develop & Maintain Conceptual Architecture. (Own)</strong>
            <ul>
              <li>
                Constantly validate applicability through abstraction of client
                engagements.
              </li>
            </ul>
          </li>
          <li>
            <strong>
              Develop & Maintain Reference Architecture. On specific Tech.
              stacks (Own)
            </strong>
            <ul>
              <li>Scoping limited to app dev stacks on the AWS cloud.</li>
            </ul>
          </li>
          <li>
            <strong>Mainframe Modernization</strong>
            <ul>
              <li>
                Check feasibility and build POC on mainframe modernization
                services offered by AWS.
              </li>
            </ul>
          </li>
          <li>
            <strong>
              Support pre-sales and lead to closure for App Dev side (Enabler)
            </strong>
          </li>
          <li>
            <strong>Support AWS alliance (Enabler)</strong>
            <ul>
              <li>POC on AWS Services</li>
              <li>Industry Trend</li>
            </ul>
          </li>
          <li>
            <strong>
              Develop & Maintain “machinery” for building Capacity for Delivery
              (Enabler)
            </strong>
            <ul>
              <li>
                Hiring Support, Coaching, Mentoring, Training, Delivery
                Architects and technical teams
              </li>
            </ul>
          </li>
          <li>
            <strong>Publish white papers and blogs</strong>
          </li>
          <li>
            <strong>
              Create re-usable components, template, best-practices etc and
              document
            </strong>
          </li>
          <li>
            <strong>
              Future roadmap include capacity building limiting to owned
              deliveries.
            </strong>
          </li>
        </ul>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-dark text-white text-center py-4">
        <div className="container">
          <p>
            &copy; {new Date().getFullYear()} Yash AWS Competency. All Rights
            Reserved.
          </p>
          <p>
            Follow us on{" "}
            <a href="https://linkedin.com" className="text-white">
              LinkedIn
            </a>
            {" | "}
            <a href="https://twitter.com" className="text-white">
              Twitter
            </a>
          </p>
        </div>
      </footer>
    </div>

  );
};

export default HomePage;
