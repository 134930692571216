import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../App.css";

const Breadcrumb = ({ history, onBreadcrumbClick }) => {
  return (
    <div className="breadcrumb">
      {history.map((crumb, index) => (
        <span key={index} onClick={() => onBreadcrumbClick(index)} className="breadcrumb-text">
          {crumb}
          {index < history.length - 1 && " > "}
        </span>
      ))}
    </div>
  );
};


const NodePage = ({ data }) => {
  const { parentPath } = useParams();
  const navigate = useNavigate();
  const { data: metadata, sasUrls } = data;
  const [nodes, setNodes] = useState([]);
  const [history, setHistory] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const baseUrl = "https://appdevstor.blob.core.windows.net/";
  const [fullPath, setFullPath] = useState(baseUrl);

  useEffect(() => {
    if (metadata && metadata.length > 0) {
      setNodes([]);
      setFullPath(
        parentPath ? `${baseUrl}${parentPath.replace(/ /g, "%20")}` : baseUrl
      );
      setHistory(parentPath ? parentPath.split("/") : []);
      if (parentPath) {
        handleCardClick(metadata, parentPath, true);
      }
    }
  }, [metadata, parentPath]);

  const handleCardClick = (urls, filterString, isInitial = false) => {
    if (!urls) return;
    const clickedFile = urls.find(
      (item) => item.path && item.path.endsWith(filterString)
    );
    if (clickedFile && isFile(filterString)) {
      const matchedSasUrl = sasUrls.find(
        (item) => item.blob_path === clickedFile.path
      );
      if (matchedSasUrl) {
        setSelectedFile(matchedSasUrl.sas_url);
      }
      return;
    }

    const filteredUrls = urls.filter(
      (item) => item.path && item.path.includes(filterString)
    );
    const transformedUrls = filteredUrls.map((item) => {
      const index = item.path.indexOf(filterString) + filterString.length;
      return item.path.slice(index + 1);
    });
    const resultSet = new Set(
      transformedUrls.map((url) => url.split("/")[0]).filter(Boolean)
    );
    setNodes(Array.from(resultSet));
    if (!isInitial) {
      setHistory((prevHistory) => {
        if (prevHistory[prevHistory.length - 1] !== filterString) {
          return [...prevHistory, filterString];
        }
        return prevHistory;
      });
      setFullPath((prevFullPath) => {
        const newPath = `${prevFullPath}/${filterString}`.replace(/ /g, "%20");
        return newPath;
      });
    }
    setSelectedFile(null);
  };

  const handleNodeClick = (node) => {
    handleCardClick(metadata, node);
  };

  const handleBreadcrumbClick = (index) => {
    const newHistory = history.slice(0, index + 1);
    const newPath = newHistory.join("/");
    setHistory(newHistory);
    navigate(`/node/${newPath}`);
    handleCardClick(metadata, newPath, true);
  };

  const isFile = (node) => {
    const fileExtensions = [".pdf", ".pptx", ".docx", ".xlsx", ".doc"];
    return fileExtensions.some((extension) => node.endsWith(extension));
  };

  const isBlog = (nodeData) => {
    return nodeData && nodeData.path.startsWith("blogs/");
  };

  return (
    <div>
      <Breadcrumb history={history} onBreadcrumbClick={handleBreadcrumbClick} />
      {selectedFile ? (
        <div className="file-viewer">
          <embed
            src={selectedFile}
            type="application/pdf"
            width="100%"
            height="600px"
          />
        </div>
      ) : (
        <div className="card-container-nodepage">
          {nodes.map((node) => {
            const nodeData = metadata.find((item) => item.path.includes(node));
            const matchedSasUrl = sasUrls.find(
              (item) => item.blob_path === nodeData?.iconimage
            );
            const imageUrl = matchedSasUrl ? matchedSasUrl.sas_url : null;

            return isBlog(nodeData) ? (
              <div
                key={node}
                onClick={() => handleNodeClick(node)}
                className="card-nodepage blog-card"
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Node"
                    className="blog-image"
                  />
                )}
                <div className="blog-content">
                  <div className="card-title-nodepage">{nodeData.name_of_document}</div>
                  <div className="node-details">
                    <p>{nodeData.summary}</p>
                    <p><strong>Author:</strong> {nodeData.author_name}</p>
                    <img src={nodeData.profileimage}></img>
                    <button className="read-more-button" onClick={() => handleNodeClick(node)}>Read More</button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={node}
                onClick={() => handleNodeClick(node)}
                className="card-nodepage"
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Node"
                    width="30px"
                    height="30px"
                    style={{ height: "80px", width: "80px" }}
                  />
                )}
                <div className="card-title-nodepage">{node}</div>
                {nodeData && (
                  <div className="node-details">
                    <p>{nodeData.summary}</p>
                    {isFile(node) && (
                      <>
                        <p><strong>Author:</strong> {nodeData.author_name}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NodePage;