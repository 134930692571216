import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { cultureIcon, whitepaperIcon, blogsIcon, casestudiesIcon } from "../assets/images";

const MorePage = () => {
  const items = ["Culture", "Whitepaper", "Blogs", "casestudies"];
  const navigate = useNavigate();

  const itemIcons = {
    Culture: cultureIcon,
    Whitepaper: whitepaperIcon,
    Blogs: blogsIcon,
    casestudies: casestudiesIcon,
  };

  return (
    <div>
      <nav className="breadcrumb" style={{ marginBottom: "10px", marginTop: "82px" }}>
        <span><Link to="/more">More</Link></span>
      </nav>
      <div className="card-container-nodepage">
        {items.map((item) => (
          <div key={item} className="card-nodepage">
            <Link to={`/node/${item.toLowerCase()}`} className="card-link">
              <img
                src={itemIcons[item]}
                alt={item}
                width="30px"
                height="30px"
                style={{ height: "100px", width: "100px" }}
              />
              <div className="card-title-nodepage">{item}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MorePage;